import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import Img_banner from "../images/banner-promotion.png";
import { Tabs } from "antd";
import $ from "jquery";

const { TabPane } = Tabs;

const PartnerPage = (props) => {
  const [state, setState] = useState({
    benefitDataSelected: false,
  });

  useEffect(() => {
    init();
  }, [state.benefitDataSelected]);

  const init = () => {
    $(".cta-expand-en").off("click");
    expandData();
  };

  const onChangeTab = () => {
    setState(!state.benefitDataSelected);
  };

  const expandData = () => {
    $(".cta-expand-en").on("click", function (e) {
      var target = $(this).data("target");

      $(target).slideToggle(400);
      $(this).closest(".copy-en").find(".content-en").toggleClass("active");

      if (!$(this).hasClass("active")) {
        $(this).addClass("active").html("Show Less");
      } else {
        $(this).removeClass("active").html("Show More");
      }
    });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      @media (max-width: 800px) {
        .benefit__special{
          display: block !important
        }
      }
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        margin: 0px auto
      }
      .benefit__special .table--benefit {
        display: block
      }
      .benefit__tab {
        font-weight: normal
      }
      .ant-tabs-tab {
        margin-right: -15px;
      }
      @media (max-width:480px){
        .ant-tabs-tab {
          margin-right: -3px;
        }
      }
      
    `,
        }}></style>

      <Layout location={props.location}>
        <SEO title="Partners &amp; Benefits " />

        <div id="contentWrap">
          <div id="index_content" className="admin-setting">
            <div className="benefit__special partner_content">
              <div className="container container--600">
                <h1 className="text--center">Partners &amp; Benefits</h1>
                <p className="text--center">
                  Live your best lifestyle by staying up to date with our latest
                  partner update
                </p>
              </div>
              <div className="container container--1000">
                <Tabs
                  className="partner__tab"
                  defaultActiveKey="benefit-all"
                  size="large"
                  onChange={onChangeTab}>
                  <TabPane tab="Ayana Group" key="benefit-all">
                    <div className="table--benefit">
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(42).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-en">
                              <h3 className="title">Kawano Group</h3>
                              <div className="content content-en">
                                <p className="desc">
                                  Redeem AYANA Rewards points for a Kawano
                                  Restaurant dining voucher and enjoy 10%
                                  discount at Kawano Group Restaurants. Enjoy
                                  Indonesia&#39;s best restaurants with every
                                  points that you earn.
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-1">
                                <span>Terms &amp; Condition</span>
                                <br />
                                1. Member Discount
                                <br />
                                - Members can enjoy a 10% discount at any Kawano
                                Group Restaurant listed below:
                                <br />
                                <ul className="dots">
                                  <li>KaDo AYANA Midplaza, JAKARTA</li>
                                  <li>Honzen AYANA Midplaza, JAKARTA</li>
                                  <li>Honzen AYANA Bali</li>
                                  <li>Honzen AYANA Komodo Resort</li>
                                  <li>Sakana Midplaza</li>
                                  <li>Sakana Mega Kuningan</li>
                                  <li>Sakana Delonix Karawang</li>
                                  <li>Tontoki Midplaza</li>
                                  <li>Tontoki Mega Kuningan</li>
                                  <li>The Grill Midplaza</li>
                                  <li>Udonku Midplaza</li>
                                  <li>Aro Aroy Midplaza</li>
                                  <li>Bistronomy Labuan Bajo</li>
                                  <li>Riverside Restaurant</li>
                                </ul>
                                - Members must log in at ayanarewards.com and
                                provide a screenshot of their membership
                                <br />
                                - No minimum &amp; maximum spend required to
                                enjoy this discount
                                <br />
                                - Discount excludes alcoholic beverages
                                <br />
                                - Discount cannot be combined with any other
                                discount/promotion
                                <br />
                                <br />
                                2. Points Redemption
                                <br />- Members can redeem their points for a
                                Kawano Group Restaurant dining voucher valued at
                                IDR 300,000 for 1,250 points through{" "}
                                <Link to="/reward">
                                  <span style={{ color: "#0000cd" }}>
                                    this page
                                  </span>
                                </Link>
                                <br />
                                - Members must print the e-voucher and bring it
                                to the Restaurant upon dining
                                <br />
                                - Voucher can be combined with cash/credit card
                                payment to settle any bill differences
                                <br />
                              </div>
                              <a
                                className="cta-expand cta-expand-en btn-toggle"
                                data-target="#collapse-1">
                                Show More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(43).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-en">
                              <h3 className="title">Riverside Golf Club</h3>
                              <div className="content content-en">
                                <p className="desc">
                                  Location: Bukit Golf Riverside Cimanggis, West
                                  Java
                                  <br />
                                  <br />
                                  Redeem points for weekday &amp; weekend golf.
                                  Play your favorite sport more with every
                                  points that you earn.
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-2">
                                <span>Terms &amp; Condition</span>
                                <br />- Members can redeem their points for a
                                free golf session at Riverside Golf Club, 2,500
                                points for 1 pax on weekdays, 7,700 points for 1
                                pax on weekends through{" "}
                                <Link to="/reward">
                                  <span style={{ color: "#0000cd" }}>
                                    this page
                                  </span>
                                </Link>
                                <br />
                                - Redemption includes green fee, golf service
                                (cart and caddy), tax and insurance
                                <br />
                                - Pre-booking is required by sending an email to
                                info@ayanarewards.com stating the preferred play
                                date
                                <br />
                                - Bookings for Saturday, Sunday and Public
                                Holidays should be made 2 weeks before
                                <br />
                                - Members must their show ID card upon
                                registration
                                <br />
                                - Members can enjoy a 10% discount on dining at
                                Riverside Restaurant
                                <br />
                                - All bookings are non-refundable and
                                non-cancellable. Your points will be deducted at
                                time of redemption and will follow the
                                redemption policy
                                <br />
                              </div>
                              <a
                                className="cta-expand cta-expand-en btn-toggle"
                                data-target="#collapse-2">
                                Show More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(41).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-en">
                              <h3 className="title">Flower Studio</h3>
                              <div className="content content-en">
                                <p className="desc">
                                  Location: Midplaza Building 1 Lvl Basement,
                                  Jakarta
                                  <br />
                                  <br />
                                  Redeem AYANA Rewards points for a Flower
                                  Studio voucher and enjoy 10% discount on
                                  custom floral arrangements. Add more beauty to
                                  your life with every points that you earn.
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-3">
                                <span>Terms &amp; Condition</span>
                                <br />
                                1. Member Discount
                                <ul className="dots">
                                  <li>
                                    Members can enjoy a 10% discount for all
                                    flower arrangements at Flower Studio
                                  </li>
                                  <li>
                                    Members must log in at ayanarewards.com and
                                    present a screenshot of their membership to
                                    staff
                                  </li>
                                  <li>
                                    No minimum &amp; maximum spend required to
                                    enjoy this discount
                                  </li>
                                  <li>
                                    Discount cannot be combined with any other
                                    discount/promotion
                                  </li>
                                </ul>
                                <br />
                                2. Points Redemption
                                <ul className="dots">
                                  <li>
                                    Members can redeem their points for a Flower
                                    Studio gift voucher valued at IDR 300,000,
                                    IDR 500,000, and IDR 1,000,000 through{" "}
                                    <Link to="/reward">
                                      <span style={{ color: "#0000cd" }}>
                                        this page
                                      </span>
                                    </Link>
                                  </li>
                                  <li>
                                    Members must print the e-voucher and bring
                                    it to Flower Studio upon redeeming their
                                    flowers
                                  </li>
                                  <li>
                                    We encourage members to pre-order flowers at
                                    least one day before redeeming, however
                                    members may order directly from Flower
                                    Studio on the same day subject to flowers
                                    available in-store on that day
                                  </li>
                                  <li>
                                    Voucher can be combined with cash/credit
                                    card payments to settle any bill differences
                                  </li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-en btn-toggle"
                                data-target="#collapse-3">
                                Show More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  {/* <TabPane tab="Airline Miles" key="benefit-ayana">
                    <div className="table--benefit">
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/KrisFlyer.jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-en">
                              <h3 className="title">KrisFlyer Miles</h3>
                              <div className="content content-en">
                                <p className="desc">
                                  Convert 2,000 AYANA Rewards points for 1,000
                                  KrisFlyer miles. The longer you stay, the more
                                  miles you will earn.
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-5">
                                <span>Terms &amp; Condition</span>
                                <ul className="dots">
                                  <li>
                                    Members are encouraged to redeem 2,000 AYANA
                                    Rewards points for 1,000 KrisFlyer miles,
                                    but not vice versa
                                  </li>
                                  <li>
                                    To redeem, please visit{" "}
                                    <Link to="/reward">
                                      <span style={{ color: "#0000cd" }}>
                                        this page
                                      </span>
                                    </Link>
                                  </li>
                                  <li>
                                    Please note that members must provide their
                                    KrisFlyer membership no, first name and last
                                    name to AYANA Rewards via email:
                                    info@ayanarewards.com
                                  </li>
                                  <li>
                                    The points conversion process may take up to
                                    7 working days
                                  </li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-en btn-toggle"
                                data-target="#collapse-5">
                                Show More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/GARUDA.jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-en">
                              <h3 className="title">GarudaMiles</h3>
                              <div className="content content-en">
                                <p className="desc">
                                  Convert 2,000 AYANA Rewards points for 1,000
                                  GarudaMiles. Follow your bliss, travel when
                                  and where you want to with every mile that you
                                  earn.
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-6">
                                <span>Terms &amp; Condition</span>
                                <ul className="dots">
                                  <li>
                                    Members are encouraged to redeem 2,000 AYANA
                                    Rewards points for 1,000 GarudaMiles, but
                                    not vice versa
                                  </li>
                                  <li>
                                    To redeem, please visit{" "}
                                    <Link to="/reward">
                                      <span style={{ color: "#0000cd" }}>
                                        this page
                                      </span>
                                    </Link>
                                  </li>
                                  <li>
                                    Please note that members must provide their
                                    GarudaMiles membership no, first name and
                                    last name to AYANA Rewards via email:
                                    info@ayanarewards.com
                                  </li>
                                  <li>
                                    The points conversion process may take up to
                                    7 working days
                                  </li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-en btn-toggle"
                                data-target="#collapse-6">
                                Show More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PartnerPage;
